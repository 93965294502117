import * as React from "react"
import { graphql, Link, navigate } from "gatsby"
import { Button, Card, Typography } from "antd"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import { formatPrice } from "../utils/format-price"
// import Img from "gatsby-image"
import {
  productCard,
  productCardCover,
  poppedProductCard,
  // productCardImage,
} from "./product-card.module.less"

const { Meta } = Card

export const ProductCard: React.FC<{
  eager: boolean
  poppedProduct: boolean
  tiltRange?: number
  className?: string
  product: {
    data: any
    url: string
    id: string
  }
}> = ({ product, eager, poppedProduct = false, tiltRange = 15, className }) => {
  const {
    data: {
      product_name: { text: title = "" },
      shopify_product: {
        // title = "",
        // handle,
        // product_type,
        priceRangeV2,
        images: [firstImage = ""] = [],
        image,
        vendor,
        variants: [{ price: priceValue = null }] = [{}],
        storefrontImages,
      } = {},
      theme_color,
      product_shot,
    } = {},
    url,
  } = product || { data: { shopify_product: {} } }
  const key = product.id
  let style: Record<string, string> = null
  if (poppedProduct) {
    // let seedString: string = ""
    let seed = 0
    for (let i = 0; i < product.id.length && i < 6; i++) {
      // seedString += product.id.charCodeAt(i).toString(16)
      seed += product.id.charCodeAt(i)
    }
    // const seed = poppedProduct ? parseInt(seedString) : null
    const tiltMod = tiltRange * 2
    const rotX = 0 //  ((seed * 3 * Math.PI) % tiltMod) - tiltRange * 2 // Aways lean in
    const rotY = 0 //((seed * 5 * Math.PI) % tiltMod) - tiltRange
    //const rotZ = ((seed * 7 + Math.PI) % tiltMod) - tiltRange
    const rotZ = (seed % tiltMod) - tiltRange
    // console.log({ seed, rotX, rotY, rotZ, tiltRange, tiltMod })
    style = {
      transform: `perspective(45em) rotateX(${rotX}deg) rotateY(${rotY}deg) rotateZ(${rotZ}deg)`,
    }
  }

  const price = priceValue ? formatPrice("USD", priceValue) : "N/A"

  const defaultImageHeight = 250
  const defaultImageWidth = 250

  let coverImage: React.ReactNode = undefined
  if (poppedProduct && !!product_shot?.gatsbyImageData) {
    coverImage = (
      // <Img
      //   fixed={product_shot.fixed}
      //   className={productCardImage}
      //   imgStyle={{ ...style, objectFit: "contain" }}
      // />
      <GatsbyImage
        objectFit={"contain"}
        image={product_shot.gatsbyImageData}
        // className={productCardImage}
        alt={"test"}
        imgStyle={{ ...style }}
      />
    )
  } else if (image) {
    // const productUrl = `/products/${slugify(product_type)}/${handle}`
    let storefrontImageData: IGatsbyImageData = null

    try {
      storefrontImageData = getShopifyImage({
        image: { ...image, originalSrc: image.src, altText: image.alt },
        // layout: "constrained",
        layout: "fullWidth",
        width: defaultImageWidth,
        height: defaultImageHeight,
        // style: { display: "inline-block" }
      })
      coverImage = (
        <GatsbyImage
          // className={productCardImage}
          alt={image.alt || ""}
          image={storefrontImageData}
          //  imgStyle={{ objectFit: "contain" }}
          // style={{ display: "inline-block" }}
          imgStyle={style}
          // objectFit={"contain"}
          // objectFit={"cover"}
          // imgStyle={{ ...style, objectFit: "contain" }}
          loading={eager ? "eager" : "lazy"}
        />
      )
    } catch (e) {
      console.error(e)
      coverImage = (
        <img alt={image.alt} src={image.src} /*className={productCardImage}*/ />
      )
    }
  } else {
  }

  // const hasImage =
  //   firstImage || Object.getOwnPropertyNames(storefrontImageData || {}).length
  const buttonStyles =
    poppedProduct && theme_color
      ? {
          color: "#fff",
          backgroundColor: theme_color,
          borderColor: theme_color,
        }
      : null

  return (
    <Link to={url} aria-label={`View ${title} product page`}>
      <Card
        // title={title}
        // hoverable
        // style={{ width: 240 }}
        className={[
          className,
          productCard,
          poppedProduct ? poppedProductCard : null,
        ].join(" ")}
        cover={
          <div className={productCardCover} /*style={style}*/>{coverImage}</div>
        }
        actions={[
          <Button
            // href={url}
            // type="primary"
            key="more"
            style={buttonStyles}
            onClick={(e) => {
              e.preventDefault()
              navigate(url)
            }}
          >
            Learn more
          </Button>,
        ]}
      >
        {/* <Link
        className={productCardStyle}
        to={productUrl}
        aria-label={`View ${title} product page`}
      > */}
        {/* {hasImage ? (
          <div className={productImageStyle} data-name="product-image-box">
            <GatsbyImage
              alt={firstImage?.altText ?? title}
              image={firstImage?.gatsbyImageData ?? storefrontImageData}
              loading={eager ? "eager" : "lazy"}
            />
          </div>
        ) : (
          <div
            style={{ height: defaultImageHeight, width: defaultImageWidth }}
          />
        )}
        <div className={productDetailsStyle}>
          <div className={productVendorStyle}>{vendor}</div>
          <h2 as="h2" className={productHeadingStyle}>
            {title}
          </h2>
          <div className={productPrice}>{price}</div>
          <div>{JSON.stringify(product)}</div>
        </div> */}
        {/* </Link> */}
        {/* <Meta title={title} description={price} /> */}
        <Typography.Title level={5}>{title}</Typography.Title>
        {/* <div>{JSON.stringify(product)}</div> */}
      </Card>
    </Link>
  )
}

export const query = graphql`
  fragment ProductCard on PrismicProduct {
    url
    id
    data {
      product_name {
        text
      }
      theme_color
      shopify_product {
        id
        handle
        product_type
        title
        image {
          id
          alt
          src
          #gatsbyImageData(aspectRatio: 1, width: 640)
        }
        variants {
          price
        }
      }
      product_shot {
        gatsbyImageData(width: 250, height: 250)
        #fixed(width: 250, height: 250) {
        #  ...GatsbyImgixFixed
        #}
        #fluid(maxHeight: 200) {
        #  ...GatsbyImgixFluid
        #}
      }
    }
  }
`
