import * as React from "react"
// import { graphql } from "gatsby"
// import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
// import { linkResolver } from "../../../utils/link-resolver"
import Layout from "../../../components/layout"
import { Seo } from "../../../components/seo"
// import SliceZone from "../components/slice-zone"
import { JsonDebugger } from "../../../components/json-debugger"
import { graphql, Link } from "gatsby"
import { ProductListing } from "../../../components/product-listing"
import { Avatar, Breadcrumb, Result, Space, Typography } from "antd"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../../../utils/link-resolver"
import PageContent from "../../../components/page-content"
const { Title } = Typography

const ElementPage = ({ data, pageContext }) => {
  if (!data) return null
  // const doc = data.prismicPage
  // Should probably be a reduce
  const products = data.suggestions.products
    .map((p) => {
      const thisElement = (p.data.analysis || []).filter(
        ({ element: { uid }, ...rest }) => {
          // console.log({ uid, rest })
          return uid === pageContext.uid
        }
      )[0]
      // console.log({ thisElement })
      // return { ...p, data: { ...p.data, ribbon: `${thisElement.percent}% ${data.element.data.symbol}` } }

      return {
        ...p,
        percent: parseFloat(thisElement.percent),
        data: {
          ...p.data,
          ribbon: (
            // `${thisElement.percent}%`{" "}
            <>
              {thisElement.percent}%{" "}
              <Avatar
                shape="square"
                style={{
                  borderRadius: 0,
                  // marginBottom: "0.5em",
                  fontSize: "20px",
                  marginTop: "-2px",
                  // backgroundColor: "#1890ff",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  // backgroundColor: "rgba(255, 255, 255, 0.9)",
                  border: "1px solid rgba(255, 255, 255, 0.4)",
                  color: "#ffffff",
                  //  color: "#1890ff",
                }}
                // size="small"
                size={16}
              >
                {/* {thisElement.symbol} */}
                {data.element.data.symbol}
              </Avatar>
            </>
          ),
        },
      }
    })
    .sort((a, b) => (a.percent > b.percent ? -1 : 1))

  return (
    <Layout>
      <Seo title={data.element.data.name} />
      <PageContent>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/learn/">Learn</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/learn/elements/">Elements</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.element.data.name}</Breadcrumb.Item>
        </Breadcrumb>
        <Space>
          <Avatar
            shape="square"
            style={{
              borderRadius: 0,
              marginBottom: "0.5em",
              backgroundColor: "#1890ff",
            }}
            size="large"
            // size={64}
          >
            {data.element.data.symbol}
          </Avatar>
          <Title>{data.element.data.name}</Title>
        </Space>
        <Result
          status="500"
          title="Construction Zone"
          subTitle="Sorry, this area is still being worked on."
        />
        <RichText
          render={data.element.data.description.richText}
          linkResolver={linkResolver}
        />
        {/* <SliceZone sliceZone={doc.data.body} /> */}
        <Title level={3}>Products with {`${data.element.data.name}`}</Title>
        <ProductListing products={products} />
      </PageContent>
      {/* <JsonDebugger json={{ pageContext, products, data }} /> */}
    </Layout>
  )
}

export const query = graphql`
  query ($uid: String) {
    element: prismicChemicalElement(uid: { eq: $uid }) {
      data {
        name
        description {
          richText
        }
        atomic_number
        symbol
      }
    }
    suggestions: allPrismicProduct(
      filter: {
        data: { analysis: { elemMatch: { element: { uid: { eq: $uid } } } } }
      }
      sort: { fields: data___product_sort, order: DESC } #sort: { fields: data___analysis___percent, order: DESC }
    ) {
      products: nodes {
        ...ProductCard
        data {
          product_name {
            text
          }
          analysis {
            percent
            element {
              id
              uid
              document {
                ... on PrismicChemicalElement {
                  url
                  data {
                    name
                  }
                }
              }
            }
          }
          product_sort
        }
      }
    }
  }
`

export default ElementPage
