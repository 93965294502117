import * as React from "react"
import { Badge, Col, Row } from "antd"
import { chunk } from "../utils/chunk"
import { ProductCard } from "./product-card"
import {
  listingContainerStyle,
  column,
  card as cardStyle,
} from "./product-listing.module.css"

// To optimize LCP we mark the first product card as eager so the image gets loaded faster
export function ProductListing({
  products = [],
  columns = 1,
  poppedProduct = false,
}) {
  // const rows = chunk(products, columns)
  // console.log(rows)
  const colSpan = Math.ceil(24 / (columns > 0 ? columns : 1))
  return (
    <div className={listingContainerStyle}>
      {/* {rows.map((productsOnRow, rowIdx) => { */}
      {/* return ( */}
      <Row
        gutter={[
          24, 24,
        ]} /*justify="space-around"*/ /*style={{ width: "100%" }}*/
        justify="center"
      >
        {products.map((p, index) => {
          const ribbon = p.data.ribbon || null
          const card = (
            <ProductCard
              className={cardStyle}
              product={p}
              key={p.id || index}
              eager={index === 0}
              poppedProduct={poppedProduct}
            />
          )
          return (
            // <span>{JSON.stringify(p)}</span>
            <Col
              key={index}
              className={column}
              xs={24}
              sm={12}
              md={8}
              lg={6}
            >
              {ribbon ? (
                <Badge.Ribbon text={ribbon}>{card}</Badge.Ribbon>
              ) : (
                card
              )}
            </Col>
          )
        })}
      </Row>
      {/* ) */}
      {/* })} */}
    </div>
  )
}
